@import "../../../node_modules/stylus-px2rem"
.main-fun-dv {
  background-color #F1F1F1 !important
  min-height 710px
  .con-dv {
    background-color #fff
    min-height 515px
    box-shadow 0px 0px 5px 2px rgba(0, 0, 0, 0.1)
    padding-top 10px
    .detail-dv {
      display flex
      flex-wrap wrap
      margin-top 30px
      .left-dv {
        width 40%
        padding-top 60px
        padding-left 50px
        text-align left
        .btn-dv {
          margin-top 50px
          margin-left 40px
          .btn {
            margin 0 auto
            background-color #3CB371
            color #ffffff
            font-size 14px
            border-color #3CB371
            height 40px
            width 100px
          }
        }
        li {
          margin-bottom 15px
          font-size 18px
          line-height 25px
          display -webkit-box
          span {
            width 10px
            height 10px
            margin-top 8px
            margin-right 15px
            border-radius 50%
            display block
            background-color #3CB371
          }
        }
      }
      .right-dv {
        position relative
        width 60%
        float right
        .img-dv {
          position relative
        }
      }
    }
  }
}
.nav {
  a {
    color #999999
    font-size 22px
  }
  .active {
    border-bottom 2px solid #3CB371
    border-radius 1px
    color #3CB371
  }
}
@media (max-width: 1000px) {
   .detail-dv {
     flex-wrap nowrap
   }
  .main-fun-dv {
    padding-bottom 70px
  }
  .main-fun-dv .con-dv .detail-dv .left-dv {
    width 90%
    padding-left 0
  }
  .main-fun-dv .con-dv .detail-dv .left-dv {
    padding-top 20px
  }
  .main-fun-dv .con-dv .detail-dv .right-dv .img-dv {
    padding-left 55px
  }
}
