@import "../../../node_modules/stylus-px2rem"
.case-dv {
  .btn-dv {
    padding 20px 0
    .btn {
      margin 0 auto
      background-color #3CB371
      color #ffffff
      font-size 14px
      border-color #3CB371
      width 100px
      height 40px
    }
  }
}
.card-title {
  margin-bottom 12px
  font-size 16px
  font-weight 700
  overflow hidden
  white-space nowrap
  text-overflow ellipsis
}
.card-body {
  display -webkit-box
  height 160px
  overflow hidden
  -webkit-box-orient vertical
  -webkit-line-clamp 4
}
.card-text:last-child {
  color #666666
  text-align justify
  font-size 14px
}
.card {
  box-shadow 0px 0px 5px 2px rgba(0, 0, 0, 0.1)
  max-width 360px
  margin 20px auto
}
.card-deck {
  justify-content center
}

