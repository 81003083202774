@import "../../node_modules/stylus-px2rem"
.slide-dv {
  width 100%
  font-family Microsoft Yahei
  .contain {
    color #fff
    height 500px
    overflow hidden
    cursor pointer
    .slide-img {
      width 100%
      opacity 1.0
      height 100%
    }
    .banner-con {
      display flex
      justify-content space-between
      text-align left
      .img-dv {
        img {
          margin-top: -70px
        }
      }
      .btn {
        background-color #ffffff
        color #3CB371
        font-size 14px
        width 100px
        height 40px
        border-color #3CB371
      }
    }
    .text {
      margin-top 15px
      margin-bottom 20px
      font-size 18px
      font-family "Adobe Arabic"
    }
  }
  .contain:first-child {
    background-color #000
    .slide-img {
      opacity 0.6
    }
  }
}
.carousel-caption {
  top 28%
}
.btn-success {
  background-color #3CB371
  border-color #3CB371
  font-size 14px
  width 100px
  height 40px
}
@media (max-width 1050px) {
  .carousel-caption:last-child {
    top 25px
  }
  .contain {
    height auto
  }
  .banner-con {
    flex-wrap wrap
  }
  .text-dv {
    margin 0 auto
  }
  .img-dv {
    margin 0 auto
  }
  .slide-dv .contain .banner-con .img-dv img {
    margin 0
  }
}
