@import "../../../node_modules/stylus-px2rem"
.partner-dv {
  background-color #f1f1f1
  padding-bottom 50px
  .img-li {
    ul {
      display flex
      flex-wrap wrap
      max-width 1100px
      margin 0 auto
      li {
        margin 25px 15px
        img {
          width 220px
          height 60px
        }
      }
    }
  }
}
