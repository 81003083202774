@import "../../../node_modules/stylus-px2rem"
.foot-dv {
  min-height 210px
  background-color #333333
  color #ffffff
  .con-dv {
    margin 0 auto
    padding-top 20px
    display flex
    .content {
      display flex
      flex-wrap wrap
      margin 0 auto
      .con {
        margin-top 20px
        h5 {
          font-size 16px
          margin-bottom 20px
        }
        text-align left
        margin-left 25px
        div {
          min-width 150px
          font-size 14px
          line-height 26px
          .contactImg {
            width 22px
            margin-right 6px
          }
          .conImg {
            padding-left 3px
          }
        }
        .ding-img {
          width 77px
          height 77px
        }
        p {
          text-align center
          font-size 14px
          margin-top 2px
        }
      }
    }
  }
  .copyright-dv {
    margin-top 20px
    font-size 12px
    color #999999
    text-align center
  }
}
.new-btn {
  border none
  padding 0
}
.btn-link {
  color #fff
}
