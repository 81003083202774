@import "../../../node_modules/stylus-px2rem"
.functino-dv {
  min-height 380px
  .function-li {
    margin-top 10px
    .ul-dv {
      list-style none
      display flex
      justify-content center
      flex-wrap wrap
      .li-dv {
        min-width 100px
        margin-right 40px
        div {
          max-width 70px
          margin 5px auto
        }
      }
    }
  }
}
