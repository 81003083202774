@import "../../../node_modules/stylus-px2rem"
.head-dv {
  .logo-text {
    font-weight 700
    color #000
  }
}
.navbar {
  background-color #fff
  border-bottom 1px solid #eee
}
.navbar-light .navbar-nav .active>.nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show>.nav-link {
  border-bottom 2px solid #3CB371 !important
  border-radius 1px
  color #3CB371
}
.navbar-light .navbar-nav .nav-link {
  margin-left 15px
  font-weight 700
  color #000
}
.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
  color #3CB371
}
.mr-auto, .mx-auto {
  text-align center
}

@media (max-width: 998px) {
  .justify-content-end {
    display none
  }
}
