@import "../../../node_modules/stylus-px2rem"
.crm-dv {
  background url("../../images/crm-imgs/banner.png") no-repeat
  background-size 100% 520px
  text-align left
  .banner-dv {
    color #ffffff
    display flex
    .left-dv {
      text-align left
      margin 140px 40px
      .btn-dv {
        padding 30px 0
        .btn {
          background #ffffff
          color #3CB371
          border-color #ffffff
        }
      }
    }
    .right-dv {
      padding 60px
    }
  }
  .title {
    text-align center !important
  }
  .product-dv {
    background-color #ffffff
    .menu {
      color #3CB371
      .num-li {
        width 90px
        font-size 48px
        line-height 86px
        text-align left
        font-weight 700
        margin-bottom 6px
        border-bottom 5px solid #3CB371
      }
    }
    .text {
      margin-right 28px
      p {
        margin 20px 0
      }
      span {
        font-weight 700
        font-size 18
      }
    }
    .left-dv {
      width 620px
    }
    .content {
      display flex
      flex-wrap wrap
      padding-left 50px
      .left-dv {
        width 60%
      }
      .right-dv {
        width 40%
        background url("../../images/crm-imgs/product1.png") no-repeat right bottom
        .img-dv {
        }
        .f-right {
          float right
        }
      }
    }
    .content-2 {
      display flex
      flex-wrap wrap
      padding-left 50px
      min-height 400px
      padding-bottom 50px
      .left-dv {
        width 60%
        .img-dv {
        }
      }
      .right-dv {
        width 40%
        padding-top 100px
      }
    }
    .content-3 {
      display flex
      flex-wrap wrap
      padding-left 50px
      min-height 463px
      background url("../../images/crm-imgs/product2.png") no-repeat
      margin-bottom 20px
      .left-dv {
        padding-top 40px
        .menu {
          color #ffffff
          .num-li {
            border-bottom 5px solid #fff
          }
        }
        width 55%
        color #fff
      }
      .right-dv {
        .img-dv {
        }
      }
    }
    .content-4 {
      display flex
      flex-wrap wrap
      padding-top 90px
      padding-left 50px
      .left-dv {
        width 50%
      }
      .right-dv {
        width 50%
        min-height 340px
        .img-dv {
        }
      }
    }
    .content-5 {
      display flex
      flex-wrap wrap
      padding-left 50px
      .left-dv {
        width 60%
      }
      .right-dv {
        width 40%
        .img-dv {
          position relative
          img:first-child {
            width 382px
            height 382px
          }
          img:last-child {
            position absolute
            left 15px
            top -15px
          }
        }
      }
    }
  }
  .case-dv {
    background-color #f1f1f1
    padding-top 20px
    padding-bottom 35px
    .con-dv {
      margin-top 20px
      .con-li {
        margin 80px 120px
        min-height 292px
        position relative
        box-shadow 0px 0px 5px 2px rgba(0, 0, 0, 0.1)
        border-radius 5px
        background-color #ffffff
        display flex
        .text-dv {
          padding 50px 30px
          h4 {
            font-weight 700
          }
          .tt-dv {
            color #666666
            margin-bottom 20px
            margin-top 10px
          }
          p {
            font-size 18px
            span {
              font-weight 700
            }
          }
        }
        .img-dv {
          overflow hidden
          border-radius 5px
          margin-top -30px
          min-width 360px
          height 273px
          img {
            width 360px
            height 273px
          }
        }
      }
    }
  }
}

@media (max-width: 1000px) {
  .crm-dv .banner-dv {
    flex-wrap wrap-reverse
  }
  .crm-dv {
    background-size auto
  }
  .crm-dv .banner-dv .left-dv {
    margin 0
  }
  .crm-dv .product-dv .content .left-dv {
    padding-left 0
    width 100%
  }
  .crm-dv .product-dv .content-2 .right-dv {
    padding-top 0px
    width 100%
  }
  .main-fun-dv .con-dv .detail-dv .right-dv {
    width 100%
  }
  .crm-dv .case-dv .con-dv .con-li {
    margin 80px 0
    flex-wrap wrap
  }
  .crm-dv .product-dv .content-2 .left-dv {
    width 100%
  }
  .crm-dv .product-dv .content .right-dv {
    width 100%
  }
  .crm-dv .product-dv .content-3 .left-dv {
    width 100%
  }
  .crm-dv .product-dv .content-4 .left-dv {
    width 100%
  }
  .crm-dv .product-dv .content-5 .right-dv {
    width 100%
  }
  .crm-dv .product-dv .content-4 .right-dv {
    width 100%
  }
  .crm-dv .product-dv .content-5 .left-dv {
    width 100%
  }
}
